.react-tabs__tab--selected {
  border-top: 3px solid #3f51b5;
}

.sales-topbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.date-selector {
  display: flex;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
}

.balancesheet-title {
  margin: 10px 0;
  text-align: center;
}