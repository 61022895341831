@import './layout.scss';


/* Add application styles & imports to this file! */

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";

.ag-theme-material {
    @include ag-theme-material((
        // use theme parameters where possible

        // Default border for cells. This can be used to specify the border-style and border-color properties e.g. `dashed red` but the border-width is fixed at 1px.
        cell-horizontal-border: solid ag-derived(secondary-border-color),
    ));

    .ag-header-cell {
        // or write CSS selectors to make customisations beyond what the parameters support
        border-right: 1px solid ag-param(secondary-border-color);
    }
}

*::-webkit-scrollbar-thumb {
    background: #0275d8;
}
*::-webkit-scrollbar {
    width: 0;
    background: #ffffff00;
}
.service-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .icon-background {
        background-color: #F1F3F6;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        width: 60px;
        height: 60px;
        padding: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    label {
        margin: $sm;
    }

    &:hover {
        .icon-background {
            background-color: #5599FF;
        }

    }

}

.create-item {
    display: flex;
    padding: $sm;
    align-items: center;
    cursor: pointer;

    div {
        margin: $sm;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &:hover {
        border-radius: $md;
        background-color: $secondary-color;

    }
}

.ListItem.Mui-selected {
    background-color: #4892ff;
    border-radius: '5px';
}