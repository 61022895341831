:root {
  --primary-color: #0075e8;
  --header-background: #183142;
  --primary-gradient: #0075e8;
}

.container {
  margin: 0;
  padding: 0;
  display: flex;
  flex: 2;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
}
.sidecontent {
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: black; */
  display: none;
}
.logoimg {
  background-image: url('../../../assets/logo.png');
  background-repeat: no-repeat;
  background-size: cover;
  width: 120px;
  height: 60px;
}
/* .poster {
  border-radius: 18px;
  width: 100%;
  height: 55vh;
  margin: 10px;
  overflow: hidden;
  margin-top: 111px;
} */
.img {
  width: 120px;
  height: 120px;
  border-radius: 6px;
  position: relative;
  line-height: 0%;
  margin-bottom: -30px;
  top: -80px;
  align-items: center;
  align-self: center;
  align-content: center;
  vertical-align: middle;
}
.content {
  padding: 30px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../../assets/IMG_0634.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
h2 {
  color: wheat;
}
.title {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  padding: 10px;
}

.sub-title {
  color: #096dd9;
  font-size: 1rem;
  font-weight: bold;
}
.error-label {
  color: red;
  font-size: .7rem;
}
.input {
  color: black;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  border: 0;
}

.btn {
  display: block;
}
.btn-primary {
  padding: 10px;
  background-color: var(--primary-color);
  border-radius: 20px;
  border: 0;
  color: #fff;
}
.center {
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
}
.column {
  flex-direction: column;
}

span.text-danger {
  color: red;
}
